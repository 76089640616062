import React, { useState } from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import {
  AlternativaContainer,
  AlternativaStyle,
  GifAcertouMondelezStyle,
  GifAcertouStyle,
  GifErrouMondelezStyle,
  GifErrouStyle,
} from "./alternativaStyle";
import TagRespostaAT from "./TagRespostaAT";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import ExplanacaoAT from "./explanacaoAT";
import SecundarioBtn from "../buttonPWA2.0/SecundarioBtn";
import CampanhaService from "../../services/CampanhaService";

function getAlternativasEnum(numAlternativas) {
  const letras = Array.from({ length: numAlternativas }, (_, i) =>
    String.fromCharCode(65 + i),
  );
  return Object.freeze(letras);
}

function AlternativaAT() {
  const {
    acerteTempo,
    obtemPontuacaoQuestaoHC,
    indiceAcerteTempo,
    componentePagina,
    TipoComponenteEnum,
    mostrarAlternativas,
    obtemPlacarFinal,
    respondeQuestao,
    proximoAcerteTempo,
    resetarContador,
    pararContador,
    isContando,
    totalDeQuestoes,
    allowsAnswerAfterTime,
  } = useAcerteTempo();

  const alternativas = acerteTempo?.questions[indiceAcerteTempo]?.alternatives;
  const AlternativasEnum = getAlternativasEnum(alternativas?.length || 0);
  const showSuccessGif = obtemPontuacaoQuestaoHC?.correct;
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  let alternativaEscolhida;
  if (obtemPlacarFinal !== null) {
    alternativaEscolhida = obtemPlacarFinal?.scores[indiceAcerteTempo];
  } else if (obtemPontuacaoQuestaoHC !== null) {
    alternativaEscolhida = obtemPontuacaoQuestaoHC;
  }

  const i18n = useStore().getState().i18n;

  const [selectedOption, setSelectedOption] = useState(0);

  const handleOptionChange = async (event) => {
    setSelectedOption(event.target.value);
    respondeQuestao(event.target.value);
    pararContador();
  };

  const shouldShowOWLGitf = campanha?.personalizationEvent?.SHOW_OWL === "true";

  const proximaQuestaoOnClick = async () => {
    resetarContador(true);
    await proximoAcerteTempo();
  };

  function determinarEstilo(
    alternativa,
    alternativaEscolhida,
    base,
    acertou,
    errou,
    correta,
  ) {
    if (alternativa.uuid === alternativaEscolhida.alternativeCorrect) {
      if (alternativaEscolhida.correct) {
        return `${base} ${acertou}`;
      } else {
        return `${base} ${correta}`;
      }
    } else if (
      alternativa.uuid === alternativaEscolhida.alternativeUuid &&
      !alternativaEscolhida.correct
    ) {
      return `${base} ${errou}`;
    }
    return base;
  }

  let render = null;
  if (alternativaEscolhida != null) {
    render = alternativas?.map((alternativa, index) => {
      let estiloDiv = determinarEstilo(
        alternativa,
        alternativaEscolhida,
        "weex-l-acerteTempo-alternativa",
        "weex-l-acerteTempo-alternativa--acertou",
        "weex-l-acerteTempo-alternativa--errou",
        "weex-l-acerteTempo-alternativa--correta",
      );

      let estiloLabel = determinarEstilo(
        alternativa,
        alternativaEscolhida,
        "weex-l-acerteTempo-alternativa--label",
        "weex-l-acerteTempo-alternativa--label--acertou",
        "weex-l-acerteTempo-alternativa--label--errou",
        "weex-l-acerteTempo-alternativa--label--correta",
      );

      let estiloSeparadorLetra = determinarEstilo(
        alternativa,
        alternativaEscolhida,
        "weex-l-acerteTempo-verticalLine",
        "weex-l-acerteTempo-verticalLine--acertou",
        "weex-l-acerteTempo-verticalLine--errou",
        "weex-l-acerteTempo-verticalLine--correta",
      );

      return (
        <AlternativaStyle key={alternativa?.uuid}>
          <div className={`${estiloDiv}`}>
            <input
              id={alternativa?.uuid}
              type="radio"
              name="alternativa"
              value={alternativa?.uuid}
              checked={selectedOption === alternativa?.uuid}
              onChange={handleOptionChange}
              disabled={true}
              readOnly={true}
            />
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              className={`${estiloLabel}`}
              htmlFor={alternativa?.uuid}
              aria-label={i18n.message(
                "alt.dinamica.acerteTempo.alternativa",
                "alternativa:",
              )}
            >
              <span className="weex-l-acerteTempo-alternativa--alfabeto">
                {AlternativasEnum[index]}
              </span>
              <div className={`${estiloSeparadorLetra}`}></div>
              <span
                className="weex-l-acerteTempo-alternativa--enunciado weex-l-acerteTempo-textos--formatoOriginal"
                dangerouslySetInnerHTML={{ __html: alternativa?.content }}
              ></span>
            </label>
          </div>
        </AlternativaStyle>
      );
    });
  } else {
    render = alternativas?.map((alternativa, index) => {
      return (
        <AlternativaStyle key={alternativa?.uuid}>
          <div className="weex-l-acerteTempo-alternativa">
            <input
              id={alternativa?.uuid}
              type="radio"
              name="alternativa"
              value={alternativa?.uuid}
              checked={selectedOption === alternativa?.uuid}
              onChange={handleOptionChange}
            />
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              className="weex-l-acerteTempo-alternativa--label"
              htmlFor={alternativa?.uuid}
              aria-label={i18n.message(
                "alt.dinamica.acerteTempo.alternativa",
                "alternativa:",
              )}
            >
              <span className="weex-l-acerteTempo-alternativa--alfabeto">
                {AlternativasEnum[index]}
              </span>
              <div className="weex-l-acerteTempo-verticalLine"></div>
              <span
                className="weex-l-acerteTempo-alternativa--enunciado weex-l-acerteTempo-textos--formatoOriginal"
                dangerouslySetInnerHTML={{ __html: alternativa?.content }}
              ></span>
            </label>
          </div>
        </AlternativaStyle>
      );
    });
  }

  // eslint-disable-next-line no-unused-vars
  const gifs = () => {
    //todo implementação temporária para sipat mondeléz codigo será removido posteriormente
    if (campanha && campanha.code === "sipatmondelez") {
      if (showSuccessGif === true) {
        return <GifAcertouMondelezStyle></GifAcertouMondelezStyle>;
      }

      if (showSuccessGif === false) {
        return <GifErrouMondelezStyle></GifErrouMondelezStyle>;
      }

      if (allowsAnswerAfterTime && !isContando && showSuccessGif === false) {
        return <GifErrouMondelezStyle></GifErrouMondelezStyle>;
      }
    }

    if (showSuccessGif === true) {
      return <GifAcertouStyle></GifAcertouStyle>;
    }

    if (showSuccessGif === false) {
      return <GifErrouStyle></GifErrouStyle>;
    }

    if (allowsAnswerAfterTime && !isContando && showSuccessGif === false) {
      return <GifErrouStyle></GifErrouStyle>;
    }
  };

  if (
    componentePagina === TipoComponenteEnum.atividadeIniciada &&
    acerteTempo?.questions[indiceAcerteTempo]?.alternatives.length > 0 &&
    mostrarAlternativas
  ) {
    return (
      <>
        <ExplanacaoAT />
        <AlternativaContainer id="alternativaContainer-221d0">
          {render}
          {shouldShowOWLGitf && gifs()}
        </AlternativaContainer>

        <WeexMobileMode>
          <TagRespostaAT />
          {!isContando ? (
            <div id="proximo-7b82dc88">
              <SecundarioBtn
                iconposition="right"
                nome={i18n.message(
                  indiceAcerteTempo === totalDeQuestoes - 1
                    ? "dinamica.acerteTempo.btn.concluir"
                    : "dinamica.acerteTempo.btn.proximo",
                  indiceAcerteTempo === totalDeQuestoes - 1
                    ? "Concluir"
                    : "Próximo",
                )}
                funcao={proximaQuestaoOnClick}
              />
            </div>
          ) : (
            ""
          )}
        </WeexMobileMode>
      </>
    );
  } else {
    return <></>;
  }
}

export default AlternativaAT;
