import React from "react";
import { useStore } from "react-redux";
import { FlatProgressBarStyle } from "./style";

function FlatProgressBar({ completed = 0 }) {
  const i18n = useStore().getState().i18n;
  const containerStyles = {
    marginTop: "0.625rem",
    height: 4,
    backgroundColor: "#efefef",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed.replace(",", ".")}%`,
    borderRadius: "inherit",
    textAlign: "right",
    transition: "width 2s ease-in-out",
  };

  return (
    <FlatProgressBarStyle>
      <div className="flatProgressBar__textContainer">
        <span className="flatProgressBar__text">
          {i18n.message("flatProgressBar.title", "Progresso atual")}
        </span>
        <span className="flatProgressBar__text--porcentage">
          {completed}
          <small>%</small>
        </span>
      </div>
      <div style={containerStyles}>
        <div style={fillerStyles} className="flatProgressBar__filler"></div>
      </div>
    </FlatProgressBarStyle>
  );
}

export default FlatProgressBar;
