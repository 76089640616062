import React, { useState } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Style } from "./style";
import PagesHeader from "../../pagesHeader/PagesHeader";
import BotaoPrimario from "../../button/BotaoPrimario";
import WeexModalAtividades from "../../weexModais/WeexModalAtividades";

function isEmpty(globalSchedule) {
  let empty = true;
  for (let item of globalSchedule) {
    if (item.activities) {
      for (let atv of item.activities) {
        if (atv.type === "MURAL_CAMPAIGN") {
          empty = false;
          break;
        }
      }
    }
  }
  return empty;
}

export default function MuralCampaigns({ globalSchedule, emptyCampaignMural }) {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();

  const [mostrarModal, setMostrarModal] = useState(false);

  const [scheduleCode, setScheduleCode] = useState(null);

  const [atividade, setAtividade] = useState(null);

  const irParaMural = (scheduleCode, atividade) => {
    let uri = `/mural/${scheduleCode}/${atividade?.uuid}`;
    navigate(uri, {
      state: { muralCampanha: true, muralSettings: atividade?.muralSettings },
    });
  };

  const ocultarPopup = () => {
    setMostrarModal(false);
  };

  const apresentacaoMural = () => {
    if (mostrarModal) {
      return (
        <WeexModalAtividades
          fecharModalCallback={ocultarPopup}
          irParaDinamica={() => {
            irParaMural(scheduleCode, atividade);
          }}
          rota={"mural"}
          conteudo_dangerouslySetInnerHTML={atividade?.presentation}
          imagemSrc={atividade?.presentationCover}
          atvName={atividade?.name}
          atvTitle={atividade?.title}
          codigoAtividade={atividade?.uuid}
          isAtividadeCompleta={atividade?.executed}
          atvExecuted={atividade?.executed}
        />
      );
    }
  };

  const murais = globalSchedule?.map((item) => {
    return (
      <Style key={item?.scheduleCode}>
        {item.activities?.map((atividade) => {
          return (
            <>
              {apresentacaoMural()}
              <BotaoPrimario
                disabledOnClick={false}
                funcao={() => {
                  if (atividade?.showPresentation) {
                    setMostrarModal(true);
                    setAtividade(atividade);
                    setScheduleCode(item.scheduleCode);
                  } else {
                    irParaMural(item.scheduleCode, atividade);
                  }
                }}
                nome={atividade?.title || atividade?.name}
                size="small"
              />
            </>
          );
        })}
      </Style>
    );
  });
  emptyCampaignMural(isEmpty(globalSchedule));
  if (!isEmpty(globalSchedule)) {
    return (
      <>
        <PagesHeader
          title={i18n.message("muralCampanha.titulo", "Mural Social")}
        />
        {murais}
      </>
    );
  } else {
    return <></>;
  }
}
