import React, { useState } from "react";
import { useStore } from "react-redux";
import BotaoAtividadeDia from "../BotaoAtividadeDia/BotaoAtividadeDia";
import { AtividadesDoDiaStyle, MoreLessBtnStyle } from "./style";
import ArrowUp from "../../svg/ArrowUp";
import ArrowDown from "../../svg/ArrowDown";
import PropTypes from "prop-types";

export default function AtividadesDoDia({
  numeroItensPorLinha,
  activitiesWithSubtitlesNoGames,
  atividadesSemGameFinal,
  hasSubtitles,
}) {
  const i18n = useStore().getState().i18n;
  const [showSubactivities, setShowSubactivities] = useState(
    activitiesWithSubtitlesNoGames.map(() => true),
  );

  AtividadesDoDia.propTypes = {
    numeroItensPorLinha: PropTypes.number,
    activitiesWithSubtitlesNoGames: PropTypes.array,
    atividadesSemGameFinal: PropTypes.array,
    hasSubtitles: PropTypes.bool,
  };

  const activityChunks = (atividades, chunks) => {
    let final = [];
    let n = atividades?.length;
    for (let i = 0; i < n; i += chunks) {
      final = [...final, atividades.slice(i, i + chunks)];
    }
    return final;
  };

  const toggleSubactivities = (index) => {
    setShowSubactivities((prev) => {
      const updatedSubactivities = [...prev];
      updatedSubactivities[index] = !updatedSubactivities[index];
      return updatedSubactivities;
    });
  };

  const btnShow = (index) => {
    return (
      <>
        <MoreLessBtnStyle onClick={() => toggleSubactivities(index)}>
          {showSubactivities[index] ? "Ver menos" : "Ver mais"}
          <div className="moreLessBtn--iconContainer">
            {showSubactivities[index] ? (
              <ArrowUp />
            ) : (
              <>
                <ArrowDown />
              </>
            )}
          </div>
        </MoreLessBtnStyle>
      </>
    );
  };

  const atividadesRender = (items, keyPrefix) => {
    return (
      <div className="atividadesDia__linhaContainer">
        {items.map(function (el, indice) {
          let habilitado = el?.enabled;
          return (
            <BotaoAtividadeDia
              key={`${keyPrefix}-${el.uuid}`}
              chave={indice}
              navegar={el?.navegar}
              habilitado={habilitado}
              imagemHabilitado={el?.enabledIcon}
              imagemDesabilitado={el?.disabledIcon}
              executado={el?.executed}
              size={{ width: 96 / numeroItensPorLinha + "%" }}
              type={el?.nome}
            />
          );
        })}
      </div>
    );
  };

  const atividadesComSubTitulos = () => {
    return (
      <>
        {activitiesWithSubtitlesNoGames.map((activityBySubtitle, index) => {
          if (activityBySubtitle?.activities.length > 0) {
            let activitiesResults = activityChunks(
              activityBySubtitle?.activities,
              numeroItensPorLinha,
            );
            const keyBase = `subtitulo-$
          {activityBySubtitle?.subtitle?.subtitle}-${index}`;
            return (
              <div className="atividadesDia__container" key={keyBase}>
                <div className="atividadesDia__subheader">
                  <div>
                    <h5>{activityBySubtitle?.subtitle?.subtitle}</h5>
                    {activityBySubtitle?.activities?.length === 1 ? (
                      <p className="weex__a11y--visuallyHidden">
                        {activityBySubtitle?.activities?.length}{" "}
                        {i18n.message("qts.atividade", "atividade")}
                      </p>
                    ) : (
                      <p className="weex__a11y--visuallyHidden">
                        {activityBySubtitle?.activities?.length}{" "}
                        {i18n.message("qts.atividades", "atividades")}
                      </p>
                    )}
                  </div>
                </div>
                {showSubactivities[index] && (
                  <>
                    {activitiesResults.map((items, key) => (
                      <div key={`${keyBase}-${key}`}>
                        {atividadesRender(items, `${keyBase}-${key}`)}
                      </div>
                    ))}
                  </>
                )}

                {btnShow(index)}
              </div>
            );
          } else {
            return <></>;
          }
        })}
      </>
    );
  };

  const atividadesSemSubtitulos = () => {
    const activitiesResults = activityChunks(
      atividadesSemGameFinal,
      numeroItensPorLinha,
    );

    return (
      <div className="atividadesDia__container">
        {activitiesResults.map((items, key) => {
          return (
            <div key={`sem-subtitulo-${key}`}>
              {atividadesRender(items, `sem-subtitulo-${key}`)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <AtividadesDoDiaStyle className="weex__scrollbar">
      {hasSubtitles ? atividadesComSubTitulos() : atividadesSemSubtitulos()}
    </AtividadesDoDiaStyle>
  );
}
