import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useStore } from "react-redux";
import ChangingProgressProvider from "../../WeexMarcadorProgresso/ChangingProgressProvider";
import { TrofeuDaCampanhaContainerStyle } from "./style";
import CampanhaService from "../../../services/CampanhaService";
import { useNavigate } from "react-router-dom";
import PrimarioBtn from "../../buttonPWA2.0/PrimarioBtn";
import RankingCampanha from "../../svg/RankingCampanha";
import RankingGamer from "../../svg/RankingGamer";

/**
 * Mostra o trofeu da campanha é a porcentagem total concluída
 * @param {Object} props
 * @param {String} props.url
 * @param {Object} props.trofeus
 */
export default function TrofeuCampanha({ url, trofeus }) {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();
  let campaignType;
  let showRankingGame;
  let anonimized;
  if (campanhaCorrente) {
    campaignType = campanhaCorrente?.campaignType;
    showRankingGame = campanhaCorrente?.showRankingGame;
    anonimized = campanhaCorrente?.anonimized;
  }

  const numeroTotalDeAtividades = trofeus.schedule.reduce(
    (total, trofeuAgenda) => total + trofeuAgenda.activities.length,
    0,
  );

  const numeroDeAtividadesFeitas = trofeus.schedule.reduce(
    (total, trofeuAgenda) =>
      total +
      trofeuAgenda.activities.filter((activity) => activity.achieved === true)
        .length,
    0,
  );

  const completionPercentage =
    (numeroDeAtividadesFeitas * 100) / numeroTotalDeAtividades;
  const porcentagemFinal = completionPercentage.toFixed(0);

  if (url === null) {
    return <div></div>;
  }

  return (
    <TrofeuDaCampanhaContainerStyle $porcentagemFinal={porcentagemFinal}>
      <div className="weex-l-trofeu-campanha-progress-bar-container animations-popIn">
        <ChangingProgressProvider
          values={[0, porcentagemFinal !== "NaN" ? porcentagemFinal : 0]}
          repeat={false}
        >
          {(porcentagemFinal) => (
            <CircularProgressbarWithChildren
              value={porcentagemFinal}
              strokeWidth={2}
              styles={{
                path: {
                  stroke: porcentagemFinal === "100" ? "#00E291" : `#847D92`,
                  strokeLinecap: "round",
                  transition: "stroke-dashoffset 1.0s ease 0s",
                },
                trail: {
                  stroke: "#DFDFDF",
                },
              }}
            >
              <div className="weex-l-trofeu-campanha-informacoes">
                <img
                  style={{ maxWidth: "3.438rem", maxHeight: "3.438rem" }}
                  src={url}
                  alt={i18n.message(
                    "alt.trofeu.campaign",
                    "Imagem da taça de conclusão da campanha",
                  )}
                />
                <div
                  aria-hidden="true"
                  className="weex-l-trofeu-campanha-informacoes__textos-container"
                >
                  <p className="weex-l-trofeu-campanha-informacoes__textos">{`${porcentagemFinal}%`}</p>
                  <p className="weex-l-trofeu-campanha-informacoes__textos">
                    {i18n.message("geral.porcentagem.concluido", "Concluído")}
                  </p>
                </div>
                <span className="weex__a11y--visuallyHidden">{`${porcentagemFinal}% ${i18n.message(
                  "geral.porcentagem.concluido",
                  "Concluído",
                )}`}</span>
              </div>
            </CircularProgressbarWithChildren>
          )}
        </ChangingProgressProvider>
      </div>
      {campaignType !== "REGULAR" && !anonimized ? (
        <div className="button-ranking-campanha" style={{ width: "93%" }}>
          <PrimarioBtn
            nome={i18n.message(
              "trofeu.ranking.campanha",
              "RANKING DA COMPETIÇÃO",
            )}
            funcao={() =>
              navigate(
                campaignType === "EQUIPE"
                  ? "/campanhaRankingPorEquipe"
                  : "/campanhaRanking",
              )
            }
            ariaStatusMessage={i18n.message(
              "aria.status.trofeus.botaoverrankingcampanha",
              "Botão ver ranking campanha clicado.",
            )}
          >
            {RankingCampanha}
          </PrimarioBtn>
        </div>
      ) : (
        ""
      )}
      {showRankingGame && !anonimized ? (
        <div style={{ width: "85%" }}>
          <PrimarioBtn
            ariaDisabled="true"
            ariaHidden="true"
            tabIndex="-1"
            nome={i18n.message("trofeu.ranking.game", "VER RANKING DO GAME")}
            funcao={() => navigate("/gameRankings")}
            ariaStatusMessage={i18n.message(
              "aria.status.trofeus.botaoverrankinggame",
              "Botão ver ranking game clicado.",
            )}
          >
            {RankingGamer}
          </PrimarioBtn>
        </div>
      ) : (
        ""
      )}
    </TrofeuDaCampanhaContainerStyle>
  );
}
