import React from "react";
import { useStore } from "react-redux";
import FeedbackVideo from "../video/FeedbackVideo";
import BotaoVideo from "../botaoVideo/BotaoVideo";
/**
 * @param {Object} props
 * @param {*} props.curtiuVideo
 * @param {*} props.comentarioVideo
 * @param {function(): void} props.isVideoCarregado
 * @param {function(): void} props.onChangeCurtiuVideo
 * @param {function(): void} props.onChangeComentarioVideo
 * @param {function(): void} props.botaoBloqueado
 * @param {function(): void} props.redirecionar
 */
function FormVideoFeedback({
  isVideoCarregado,
  curtiuVideo,
  comentarioVideo,
  onChangeCurtiuVideo,
  onChangeComentarioVideo,
  botaoBloqueado,
  redirecionar,
  classNameDesktopFeedbackDiv,
  classNameDesktopBotaoDiv,
}) {
  const i18n = useStore().getState().i18n;
  return (
    <>
      {isVideoCarregado() && (
        <>
          <div className={`${classNameDesktopFeedbackDiv || ""}`}>
            <FeedbackVideo
              pergunta={i18n.message(
                "video.feedback.questao",
                "Escreva sua dúvida ou comentário ?",
              )}
              placeholder={i18n.message(
                "video.feedback.comentario",
                "Texto (opcional)",
              )}
              curtiuVideo={curtiuVideo}
              comentarioVideo={comentarioVideo}
              onChangeCurtiuVideo={onChangeCurtiuVideo}
              onChangeComentarioVideo={onChangeComentarioVideo}
            />
          </div>
          <div className={`${classNameDesktopBotaoDiv || ""}`}>
            {botaoBloqueado() && (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "300",
                  color: "#524C5E",
                  marginBottom: "10px",
                }}
              >
                <small style={{ fontSize: "1.1rem" }}>
                  {i18n.message(
                    "video.aviso.botaodesbloquear",
                    "Veja o vídeo até o final",
                  )}
                </small>
              </p>
            )}
            <div style={{ marginBottom: "2rem" }}>
              <BotaoVideo
                funcao={redirecionar}
                disabled={botaoBloqueado()}
                classeComplementar={
                  botaoBloqueado() ? "botao-video-desabilitado" : ""
                }
                nome={i18n.message("video.entendi", "Concluir atividade")}
              />
              {!botaoBloqueado() && (
                <span
                  className="weex__a11y--visuallyHidden"
                  role="alert"
                  aria-live="assertive"
                >
                  {i18n.message(
                    "aria.label.video.alert.botao.desbloqueado",
                    "Botão concluir atividade desbloqueado!",
                  )}
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default FormVideoFeedback;
