import styled from "styled-components";

export const PontuacaoEquipeStyle = styled.div`
  .team__position {
    display: flex;
    padding: 0.25rem;
    justify-content: center;
    align-items: center;
    background-color: #e5e5e5;
    border-radius: 50%;
    min-width: 2rem; // Tamanho mínimo para largura
    min-height: 2rem; // Tamanho mínimo para altura
    aspect-ratio: 1; // Mantém a proporção 1:1 (circular)
  }

  .team__name {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .team_on_ranking {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem; //
    background-color: #f3f3f3;
    margin-bottom: 0.625rem; //10px;
    border-radius: 0.625rem; //10px;
    font-size: 1.125rem; //18px;
  }

  .ranking__position__name {
    display: flex;
    align-items: center;
    gap: 1rem; //16px;
  }

  .ranking__score {
    display: flex;
    background-color: #d2f4e0;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;
    border-radius: 0.75rem;
    font-weight: 600;
    font-size: 0.95rem; //18px;

    h4,
    span {
      color: #005925;
    }
  }

  .team__imageContainer {
    width: 3.5rem; // 56px;
    height: 3.5rem; // 56px;
    background-color: #e7e7e7;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 1rem;
    margin-right: 1rem;

    .cardTeam__imageContainer--logoDefault {
      width: 2.25rem; // 36px;
      height: 2.25rem; // 36px;
    }

    .cardTeam__imageContainer--logo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
