import React, { useState } from "react";
import { useStore, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WeexModal from "../../comps/weexModais/WeexModal";
import CampanhaService from "../../services/CampanhaService";
import TermosCondicoesService from "../../services/TermosCondicoesService";
import UsuarioService from "../../services/UsuarioService";
import TermosCondicoes from "../../comps/AcceptLegalTerm/TermosCondicoes";
import { TermsConditionsStyled } from "./style";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
function AcceptLegalTerm() {
  const dispatch = useDispatch();
  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService?.campanhaCorrente();
  const i18n = useStore().getState().i18n;
  const termosCondicoesService = new TermosCondicoesService();
  const usuarioService = new UsuarioService();

  const tipoInscricao = campanhaCorrente?.tipoInscricao;
  const elegibilidade = campanhaCorrente?.restrictEligibility;
  const campaignType = campanhaCorrente?.campaignType;
  const isCampaignTypeEquipe = campaignType === "EQUIPE";

  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
  });
  const navigate = useNavigate();

  const handleLegalTerm = () => {
    termosCondicoesService.aceiteTermoObrigatorio((erro, sucesso) => {
      if (erro) {
        setErro({
          isWrong: true,
          titulo: i18n.message("geral.erro.network.titulo", "Há algo errado"),
          mensagem: i18n.message(
            "geral.erro.network.mensagem",
            "Houve um problema no processamento do seu pedido, por favor verifique sua internet ou contate o suporte",
          ),
        });
        return;
      }
      if (sucesso) {
        let usuario = usuarioService.usuarioCorrente();
        usuario.legalTerm = sucesso.acceptLegalTerm;
        usuarioService.setUsuarioCorrente(usuario);
        usuarioService.setUsuarioAvatar(usuario?.avatarDTO);
        usuarioService.setUserTeamUuid(sucesso?.team);
        dispatch({ type: "usuariologado", payload: usuario });
        dispatch({ type: "userTeamUuid", payload: sucesso?.team });
        dispatch({ type: "avatar", payload: usuario?.avatarDTO });
        campanhaService.contadorCampanha((erro, sucesso) => {
          if (erro) {
            setErro({
              isWrong: true,
              titulo: i18n.message(
                "atividades.erro.dias.habilitados",
                "Erro obter lista de dias habilitados",
              ),
            });
            return;
          }
          if (sucesso) {
            if (sucesso.length > 0) {
              dispatch({ type: "campanhaComecou", payload: true });
              navigate("/atividades");
              window.location.reload();
            } else {
              dispatch({ type: "campanhaComecou", payload: false });
              if (
                elegibilidade &&
                tipoInscricao === "ELEGIBILIDADE" &&
                isCampaignTypeEquipe &&
                !usuarioService.usuarioPossuiEquipe()
              ) {
                navigate("/selectTeam");
                return;
              }
              navigate("/home");
            }
          }
        });
      }
    });
  };

  const callbackModal = () => {
    if (erro.isWrong === true) {
      setErro({ isWrong: false });
    }
  };

  return (
    <>
      {erro.isWrong === true ? (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}

      <TermsConditionsStyled>
        <div className="termsConditions__container">
          <div>
            <h3 className="termsConditions__title--marginBottom">
              {i18n.message("registro.legal.termos", " Termos e Condições ")}
            </h3>
            <div>
              <h4 className="termsConditions__texts">
                <span aria-hidden="true">
                  {i18n.message(
                    "aceiteTermos.condicao",
                    "Para acessar a campanha, é necessário ler e aceitar os",
                  )}{" "}
                </span>
                <TermosCondicoes />
                <span aria-hidden="true">
                  {i18n.message("registro.legal.daweex", "da Weex")}
                </span>
              </h4>
            </div>
          </div>

          <div className="termsConditions__buttonContainer">
            <PrimarioBtn
              nome={i18n.message(
                "aceiteTermos.acao",
                "Aceitar termos e condições",
              )}
              funcao={handleLegalTerm}
              ariaStatusMessage={i18n.message(
                "aria.status.acceptlegalterm.botaoaceitartermos",
                "Botão aceitar termos clicado.",
              )}
            />
          </div>
        </div>
      </TermsConditionsStyled>
    </>
  );
}

export default AcceptLegalTerm;
