import styled from "styled-components";

/**
 * CSS base dos botões da Weex 3.0
 */
export const BaseButton = styled.button`
  cursor: pointer;
  border-radius: 2.5rem;
  padding-top: ${(props) => {
    if (props.$size === "small") {
      return "0.25rem";
    } else {
      return "0.5rem";
    }
  }};
  padding-bottom: ${(props) => {
    if (props.$size === "small") {
      return "0.25rem";
    } else {
      return "0.5rem";
    }
  }};
  padding-left: ${(props) => {
    if (props.$size === "small") {
      if (props.$hasname) {
        if (props.$iconposition === "right") {
          return "0.75rem";
        }
        if (props.$iconposition === "none") {
          return "0.25rem";
        }
      }
      return "0.25rem";
    } else {
      if (props.$hasname) {
        if (props.$iconposition === "right") {
          return "0.75rem";
        }
        if (props.$iconposition === "none") {
          return "0.5rem";
        }
      }
      return "0.5rem";
    }
  }};
  padding-right: ${(props) => {
    if (props.$size === "small") {
      if (props.$hasname) {
        if (props.$iconposition === "left") {
          return "0.75rem";
        }
        if (props.$iconposition === "none") {
          return "0.25rem";
        }
      }
      return "0.25rem";
    } else {
      if (props.$hasname) {
        if (props.$iconposition === "left") {
          return "0.75rem";
        }
        if (props.$iconposition === "none") {
          return "0.5rem";
        }
      }
      return "0.5rem";
    }
  }};

  text-align: left;
  text-transform: uppercase;
  font-size: 0.875rem; // 14px;
  line-height: 0.875rem; // 14px;
  font-weight: 700;

  .baseButton__text {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.875rem; // 14px;
    line-height: 0.875rem; // 14px;
    font-weight: 700;
  }

  .baseButton__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 0.5rem;
  }

  .baseButton__iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    width: 0.75rem; //12px;
    height: 0.75rem; //12px;
    padding: 0.375rem;
    border-radius: 2.5rem;
  }

  svg {
    width: 0.75rem; //12px;
    height: 0.75rem; //12px;
  }

  &:disabled {
    cursor: not-allowed;
    filter: none;
    border-color: #efefef;
    background-color: #efefef;
    color: #7c7c7c;

    path {
      stroke: #7c7c7c;
    }

    box-shadow: ${(props) => {
      return props.theme.sombraSecundaria;
    }};
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    &:hover,
    &:active {
      filter: contrast(0.92);
    }
  }
  /* #endregion DESKTOP */
`;

/**
 * CSS Botão primário
 */
export const ButtonPrimarioStyle = styled(BaseButton)`
  background-color: ${(props) => {
    return props.theme.fundoSecundario || `#533888`;
  }};
  color: ${(props) => {
    return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraPrimaria;
  }};
  border: 0.063rem solid
    ${(props) => {
      return props.theme.fundoSecundario || `#533888`;
    }};

  .baseButton__text {
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
    }};
  }

  /* .baseButton__iconContainer {
    background-color: ${(props) => {
    return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
  }};
  } */

  path {
    stroke: ${(props) => {
      return props.theme.fundoSecundario || `rgb(239, 231, 102)`;
    }};
  }
`;

/**
 * CSS Botão secundário
 */
export const ButtonSecundarioStyle = styled(BaseButton)`
  background-color: "#F3F3F8";
  padding: 1rem;
  color: ${(props) => {
    return props.theme.cardLogadoTextoTerceiro;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};
  border: 0.063rem solid
    ${(props) => {
      return props.theme.fundoTres || `#533888`;
    }};

  .baseButton__text {
    color: ${(props) => {
      return props.theme.cardLogadoTextoTerceiro;
    }};
  }

  .baseButton__iconContainer {
    background-color: ${(props) => {
      return props.theme.fundoTres;
    }};
    filter: contrast(0.92);
  }

  path {
    stroke: ${(props) => {
      return props.theme.cardLogadoTextoTerceiro || `rgb(239, 231, 102)`;
    }};
  }
`;

/**
 * CSS Botão Terciário
 */
export const ButtonTertiaryStyle = styled(BaseButton)`
  background-color: ${(props) => {
    return props.theme.cardLogadoFundo;
  }};
  color: ${(props) => {
    return props.theme.cardLogadoTextoTerceiro;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};
  border: 0.063rem solid
    ${(props) => {
      return props.theme.cardLogadoFundo || `#533888`;
    }};

  .baseButton__text {
    color: ${(props) => {
      return props.theme.cardLogadoTextoTerceiro;
    }};
  }

  .baseButton__iconContainer {
    background-color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    path {
      stroke: ${(props) => {
        return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
      }};
    }
  }

  path {
    stroke: ${(props) => {
      return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
    }};
  }
`;

/**
 * CSS Botão da Agenda (Opções de ver tudo, disponíveis e concluídas)
 */
export const ButtonScheduleStyle = styled(BaseButton)`
  text-transform: none;
  background-color: ${(props) => {
    if (props.$filterName) {
      return "#201d1d";
    } else {
      return props.theme.cardLogadoTextoPrimeiro;
    }
  }};

  .baseButton__text {
    text-transform: none;
    color: ${(props) => {
      if (props.$filterName) {
        return "#ffffff";
      } else {
        return props.theme.textosCorFundoSecundario;
      }
    }};
  }
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};
  border: 0.063rem solid
    ${(props) => {
      if (props.$filterName) {
        return "#201d1d";
      } else {
        return props.theme.cardLogadoTextoPrimeiro;
      }
    }};
`;

// Css opções ranking por equipe

export const ButtonTeamRankingStyle = styled(BaseButton)`
  background-color: ${(props) => {
    if (props.$filterName) {
      return "#201d1d";
    } else {
      return props.theme.cardLogadoTextoPrimeiro;
    }
  }};

  .baseButton__text {
    color: ${(props) => {
      if (props.$filterName) {
        return "#ffffff";
      } else {
        return props.theme.textosCorFundoSecundario;
      }
    }};
  }
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};
  border: 0.063rem solid
    ${(props) => {
      if (props.$filterName) {
        return "#201d1d";
      } else {
        return props.theme.cardLogadoTextoPrimeiro;
      }
    }};

  @media screen and (min-width: 1024px) {
    &:hover {
      background-color: ${(props) => {
        if (props.$filterName) {
          return "#1a1717";
        } else {
          return props.theme.cardLogadoTextoHover;
        }
      }};
      box-shadow: ${(props) => props.theme.sombraHover};
      border-color: ${(props) => {
        if (props.$filterName) {
          return "#1a1717";
        } else {
          return props.theme.cardLogadoTextoHover;
        }
      }};
    }
  }
`;
