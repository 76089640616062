import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useStore, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DiaSemanaAgenda from "../../comps/agenda/CardDiaSemanaAtividades/DiaSemanaAgenda";
import WeexSpinner from "../../comps/WeexSpinner/WeexSpinner";
import WeexModalAtividades from "../../comps/weexModais/WeexModalAtividades";
import AgendaService from "../../services/AgendaService";
import CampanhaService from "../../services/CampanhaService";
import FeedbackService from "../../services/FeedbackService";
import LoaderService from "../../services/LoaderService";
import MensagemVideoService from "../../services/MensagemVideoService";
import TemaService from "../../services/TemaService";
import UsuarioService from "../../services/UsuarioService";
import PagesHeader from "../../comps/pagesHeader/PagesHeader";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import { AtividadesStyle } from "./style";
import PrepararAgenda from "../../services/PrepararAgenda";
import ScheduledButton from "../../comps/agenda/BotaoOpcoesAgenda/ScheduledButton";
import FlatProgressBar from "../../comps/agenda/barraProgresso/FlatProgressBar";
import CampanhaRankings from "../campanhaRanking/CampanhaRankings";
import WeexDesktopMode from "../../comps/WeexMode/WeexDesktopMode";
import MuralCampaigns from "../../comps/agenda/muralCampaigns/MuralCampaigns";
import PreSelecaoInteressesService from "../../services/PreSelecaoInteressesService";
import CampanhaRankingsPorEquipe from "../campanhaRanking/CampanhaRankingsPorEquipe";

const prepararAgenda = new PrepararAgenda();

let ALL = "ALL";
let DISPONIVEL = "DISPONIVEL";
let CONCLUIDO = "CONCLUIDO";

function Atividades() {
  const userTeamUuid = useSelector((state) => state.userTeamUuid);
  const i18n = useStore().getState().i18n;
  const icone = useStore().getState().icone;

  const iconeCustomizado = icone?.finalIcone;
  const possuiIconeCustomizado = iconeCustomizado?.length > 0;

  const dispatch = useDispatch();
  const usuarioService = new UsuarioService();
  const campanhaService = new CampanhaService();
  const feedbackService = new FeedbackService();
  const agendaService = useMemo(() => new AgendaService(), []);
  const mensagemVideoService = new MensagemVideoService();
  const navigate = useNavigate();

  const usuario = usuarioService?.usuarioCorrente();
  const usuarioNaoAceitouLegalTerm = usuario && !usuario?.legalTerm;
  const campanha = campanhaService?.campanhaCorrente();

  let campanhaPossuiLegalTerm;
  let campanhaPossuiInteressesObrigatorios;
  let campaignType;
  let flow;
  let tipoInscricao;
  let elegibilidade;
  let isCampaignTypeEquipe;
  if (campanha) {
    campanhaPossuiLegalTerm = campanha && campanha?.legalTerm;
    campanhaPossuiInteressesObrigatorios =
      campanha && campanha?.requiredInterests;
    campaignType = campanha?.campaignType;
    isCampaignTypeEquipe = campaignType === "EQUIPE";
    tipoInscricao = campanha?.tipoInscricao;
    elegibilidade = campanha?.restrictEligibility;
    flow = campanha?.flow;
  }
  const preSelecaoInteressesService = new PreSelecaoInteressesService();
  const adminOrGestor = usuarioService?.isAdmin() || usuarioService?.isGestor();

  const godModeEnabled =
    usuarioService?.isAdmin() || usuarioService?.isGestor();

  const [isEmptyCampaignMural, setIsEmptyCampaignMural] = useState(true);
  const [feedback, setFeedback] = useState(null);
  const [schedule, setSchedule] = useState();
  const [filterName, setFilterName] = useState(ALL);
  let [porcentagemAtividadesFeitas, setPorcentagemAtividadesFeitas] =
    useState(0);
  const [apresentacaoAtividade, setApresentacaoAtividade] = useState({
    apresentacao: "",
    apresentacaoCapa: "",
    nome: "",
    titulo: "",
    icone: "",
    executed: false,
  });
  const [globalSchedule, setGlobalSchedule] = useState(null);
  const [diasSemana, setDiasSemana] = useState();
  const [processando, setProcessando] = useState(true);
  const [, setErro] = useState(null);
  const [rota, setRota] = useState("");
  const [codigoAgenda, setCodigoAgenda] = useState("");
  const [codigoAtividade, setCodigoAtividade] = useState("");
  const [mostrarModal, setMostrarModal] = useState(false);
  const [isAtividadeClicadaCompleta, setIsAtividadeClicadaCompleta] =
    useState(false);

  if (!campanha) {
    navigate("/");
  }

  const navegar = useCallback(
    (
      rota,
      codigoAgenda,
      codigoAtividade,
      imagemTrofeuHabilitado,
      atividade,
      nomeDia,
      showPresentation,
    ) => {
      setIsAtividadeClicadaCompleta(atividade?.executed);
      if (
        atividade != null &&
        rota !== "presencial" &&
        rota !== "acerteTempo" &&
        showPresentation === true
      ) {
        setApresentacaoAtividade({
          apresentacao: atividade?.presentation,
          apresentacaoCapa: atividade?.presentationCover,
          nome: atividade?.name,
          titulo: atividade?.title,
          icone: atividade?.enabledIcon,
          executed: atividade?.executed,
        });
        setRota(rota);
        setCodigoAgenda(codigoAgenda);
        setCodigoAtividade(codigoAtividade);
        setMostrarModal(true);
      } else {
        navigate(`/${rota}/${codigoAgenda}/${codigoAtividade}`, {
          state: {
            nomeDia: nomeDia,
          },
        });
      }
      new LoaderService()?.load(imagemTrofeuHabilitado);
    },
    [navigate],
  );

  const location = useLocation();
  const { nextActivity } = location.state || {};
  const [lastActivity, setLastActivity] = useState(true);
  const [executada, setExecutada] = useState(false);

  // Função auxiliar para processar nextActivity
  const processNextActivity = useCallback(
    (activity) => {
      activity.key = `${activity?.type}_${activity?.uuid}`;
      activity.nome = i18n.getNomeDasAtividades(activity?.type, activity?.name);
    },
    [i18n],
  );

  // Função auxiliar para navegação
  const realizarNavegacao = useCallback(
    (activity, dia) => {
      navegar(
        activity?.route,
        activity?.scheduleCode,
        activity?.uuid,
        activity?.trophyEnabled,
        activity,
        dia?.nome,
        activity?.showPresentation,
      );
      setExecutada(false);
    },
    [navegar],
  );

  useEffect(() => {
    if (
      nextActivity &&
      lastActivity &&
      nextActivity?.uuid !== lastActivity?.uuid &&
      !executada
    ) {
      setExecutada(true);
      processNextActivity(nextActivity); // Processa a nextActivity

      agendaService?.agendaCorrente((erro, agenda) => {
        if (agenda) {
          const dia = agenda?.schedule?.find(
            (item) => item.scheduleCode === nextActivity?.scheduleCode,
          );

          setLastActivity(nextActivity); // Armazena a atividade como a última

          realizarNavegacao(nextActivity, dia); // Realiza a navegação
        }
      });
    }
  }, [
    nextActivity,
    lastActivity,
    i18n,
    navegar,
    agendaService,
    executada,
    processNextActivity,
    realizarNavegacao,
  ]);

  const sincronizarCampanha = useCallback(async () => {
    await new CampanhaService()?.useSincronizarCampanha();
  }, []);
  useEffect(() => {
    dispatch({ type: "mainBackgroundColor", payload: "#ffffff" });
    setProcessando(true);

    const sincronizarCampanhaCallback = async (callback) => {
      try {
        await sincronizarCampanha();
        callback();
      } catch (error) {
        console.error(error);
      }
    };

    sincronizarCampanhaCallback(async () => {
      if (usuarioNaoAceitouLegalTerm && campanhaPossuiLegalTerm) {
        navigate("/accept");
      }

      /**
       * Adiciona o background-img vindo do estilos
       */
      let service = new TemaService(campanha);
      service?.aplicarTema("HOME");

      /**
       * Mensagem em vídeo de boas vindas ou outras informações
       */
      mensagemVideoService?.obterMensagemDeVideo((erro, sucesso) => {
        if (erro) {
          setErro(
            i18n.message(
              "atividades.erro.video.prefixo",
              "Erro ao obter mensagem de video da campanha: ",
            ) + erro,
          );
          return;
        }
        if (sucesso) {
          if (sucesso?.length > 0) {
            dispatch({ type: "messageVideo", payload: sucesso });
            dispatch({ type: "SHOW_MODAL", payload: "welcomeVideo" });
          }
        }
      });

      /**
       * Feedback da campanha geral
       */
      if (feedbackService?.feedbackHabilitado()) {
        feedbackService?.feedbackRespondido(callbackFeedback);
      }

      /**
       * Agenda
       */
      await handleAdminOrGestor();
      agendaService?.agendaCorrente((erro, agenda) => {
        if (erro) {
          navigate("/");
          return;
        }
        if (agenda && !diasSemana) {
          calcPercentage(agenda?.schedule);
          campanhaService?.contadorCampanha(async (erro, _sucesso) => {
            if (erro) {
              setErro(
                i18n.message(
                  "atividades.erro.dias.habilitados",
                  "Erro ao obter a lista de dias habilitados da campanha: ",
                ) + erro,
              );
              navigate("/");
              return;
            }
            if (
              campanhaPossuiInteressesObrigatorios &&
              !agenda?.pickedInterests &&
              !adminOrGestor
            ) {
              dispatch({ type: "campanhaComecou", payload: false });
              if (
                elegibilidade &&
                tipoInscricao === "ELEGIBILIDADE" &&
                isCampaignTypeEquipe &&
                userTeamUuid === null &&
                !adminOrGestor
              ) {
                navigate("/selectTeam");
                return;
              }
              navigate("/interests");
              return;
            }
            if (
              campaignType === "EQUIPE" &&
              userTeamUuid === null &&
              !adminOrGestor
            ) {
              navigate("/selectTeam");
              return;
            }
            dispatch({ type: "campanhaComecou", payload: true });
            setProcessando(false);
            setSchedule(agenda?.schedule);
            setGlobalSchedule(agenda?.globalSchedule);
            const checkStartDaCampanha =
              campanhaService?.obterCheckStartDaCampanha();
            prepararAgenda?.prepareSchedule(
              agenda,
              checkStartDaCampanha,
              flow,
              possuiIconeCustomizado,
              iconeCustomizado,
              i18n,
              godModeEnabled,
              navegar,
            );
            let dias = agenda?.schedule?.map((agendaDoDia) => {
              return renderizaDiasDaAgenda(agendaDoDia, ALL);
            });
            setDiasSemana(dias);
          });
        }
      });
    });
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterSchedule = (filterType) => {
    let dias = schedule?.map((agendaDoDia) => {
      return renderizaDiasDaAgenda(agendaDoDia, filterType);
    });
    setDiasSemana(dias);
  };

  const calcPercentage = (schedule) => {
    if (schedule && schedule.length > 0) {
      let totalActivities = 0;
      let totalExecutedActivities = 0;
      schedule.forEach((obj) => {
        obj.activities.forEach((activity) => {
          if (activity.trophyCount) {
            totalActivities++;
            if (activity.executed) {
              totalExecutedActivities++;
            }
          }
        });
      });

      const porcentagem = (totalExecutedActivities * 100) / totalActivities;
      setPorcentagemAtividadesFeitas(porcentagem);
    }
  };

  const handleAdminOrGestor = async (agenda) => {
    if (adminOrGestor) {
      try {
        await registerInteressesAdminOrGestor(agenda);
        dispatch({ type: "campanhaComecou", payload: true });
      } catch (erro) {
        console.error("Erro ao registrar interesses:", erro);
      }
    }
  };

  const registerInteressesAdminOrGestor = (agenda) => {
    return new Promise((resolve, reject) => {
      if (campanhaPossuiInteressesObrigatorios && !agenda?.pickedInterests) {
        const interesseSubmit = [];
        preSelecaoInteressesService?.selecaoDeInteresses(
          interesseSubmit,
          (erro, sucesso) => {
            if (erro) {
              console.error(
                "Se for gestor ou admin, deve registrar todos os interesses",
                erro,
              );
              reject(erro); // Rejeita a Promise com o erro
            } else if (sucesso) {
              console.info("Interesses criados com sucesso!!");
              resolve(sucesso); // Resolve a Promise com o sucesso
            }
          },
        );
      } else {
        resolve(); // Resolve a Promise caso o tipo de agendamento não seja "INTERESSES"
      }
    });
  };

  function renderizaDiasDaAgenda(agendaDoDia, filterType) {
    const checkStartDaCampanha = campanhaService?.obterCheckStartDaCampanha();
    let diaEnabled = checkStartDaCampanha?.some(
      (diaHabilitado) =>
        diaHabilitado === agendaDoDia?.day ||
        (agendaDoDia?.extendedSchedule === true &&
          diaHabilitado === "AGENDA_PROLONGADA"),
    );

    if (
      filterType === DISPONIVEL &&
      diaEnabled &&
      agendaDoDia?.completionPercentage < 100
    ) {
      return (
        <div key={agendaDoDia?.scheduleCode} className="animations-popIn">
          <DiaSemanaAgenda agendaDoDia={agendaDoDia} />
        </div>
      );
    } else if (filterType === CONCLUIDO && diaEnabled) {
      const day = prepararAgenda?.filterExecuted(agendaDoDia);
      if (day?.completionPercentage > 0) {
        return (
          <div key={agendaDoDia?.scheduleCode} className="animations-popIn">
            <DiaSemanaAgenda agendaDoDia={day} />
          </div>
        );
      }
    } else if (filterType === ALL) {
      return (
        <div key={agendaDoDia?.scheduleCode} className="animations-popIn">
          <DiaSemanaAgenda agendaDoDia={agendaDoDia} />
        </div>
      );
    }
  }

  function irParaDinamica() {
    navigate(`/${rota}/${codigoAgenda}/${codigoAtividade}`, {
      state: {
        icon: apresentacaoAtividade?.icone,
      },
    });
  }

  function ocultarPopup() {
    setMostrarModal(false);
  }

  const callbackFeedback = (error, result) => {
    if (error) {
      setFeedback(null);
    } else {
      setFeedback(result);
    }
  };

  const rankingIndividual = () => {
    if (campaignType === "INDIVIDUAL") {
      return (
        <WeexDesktopMode className="atividades__containerRanking">
          <CampanhaRankings isRenderedInSchedulePage={true} />
        </WeexDesktopMode>
      );
    }
  };
  const rankingPorEquipe = () => {
    if (campaignType === "EQUIPE") {
      return (
        <WeexDesktopMode className="atividades__containerRanking">
          <CampanhaRankingsPorEquipe isRenderedInSchedulePage={true} />
        </WeexDesktopMode>
      );
    }
  };

  const campaignAtividades = () => {
    return (
      <section>
        {!isEmptyCampaignMural ? (
          <hr
            style={{
              marginBottom: "1.5rem",
              backgroundColor: "#e2e2e2",
              height: "1px",
              border: "0",
            }}
          />
        ) : (
          ""
        )}
        <PagesHeader title={i18n.message("atividades.titulo", "Atividades")} />
        <div className="atividades__geralInfos">
          <div className="atividades__geralInfos--filters">
            <ScheduledButton
              filterName={filterName === ALL}
              funcao={() => {
                filterSchedule(ALL);
                setFilterName(ALL);
              }}
              nome={i18n.message(
                "atividades.options.botao.verTudo",
                "Ver Tudo",
              )}
              ariaStatusMessage={i18n.message(
                "alt.atividades.options.botao.verTudo",
                "Botão Ver Tudo clicado",
              )}
              disabledOnClick={false}
            />
            <ScheduledButton
              filterName={filterName === DISPONIVEL}
              funcao={() => {
                filterSchedule(DISPONIVEL);
                setFilterName(DISPONIVEL);
              }}
              nome={i18n.message(
                "atividades.options.botao.disponiveis",
                "Disponíveis",
              )}
              ariaStatusMessage={i18n.message(
                "alt.atividades.options.botao.disponiveis",
                "Botão Disponíveis clicado",
              )}
              disabledOnClick={false}
            />
            <ScheduledButton
              filterName={filterName === CONCLUIDO}
              funcao={() => {
                filterSchedule(CONCLUIDO);
                setFilterName(CONCLUIDO);
              }}
              nome={i18n.message(
                "atividades.options.botao.concluidos",
                "Concluídas",
              )}
              ariaStatusMessage={i18n.message(
                "alt.atividades.options.botao.concluidos",
                "Botão Concluídas clicado",
              )}
              disabledOnClick={false}
            />
          </div>
          <FlatProgressBar
            completed={porcentagemAtividadesFeitas.toLocaleString("pt-BR", {
              maximumFractionDigits: 2,
            })}
          />
        </div>
        <div>{diasSemana}</div>
      </section>
    );
  };

  const campaignFeedback = () => {
    if (feedback && !feedback?.answered) {
      return (
        <section className="atividades__feedbackBtnContainer">
          <PrimarioBtn
            disabledOnClick={false}
            nome={i18n.message(
              "feedback.acao",
              "Conte-nos sobre sua experiência",
            )}
            funcao={() => {
              navigate("/feedback");
            }}
          />
        </section>
      );
    }
  };

  const emptyCampaignMural = (value) => {
    setIsEmptyCampaignMural(value);
  };

  return (
    <AtividadesStyle $campaignType={campaignType}>
      {mostrarModal ? (
        <WeexModalAtividades
          fecharModalCallback={ocultarPopup}
          irParaDinamica={irParaDinamica}
          rota={rota}
          conteudo_dangerouslySetInnerHTML={apresentacaoAtividade?.apresentacao}
          imagemSrc={apresentacaoAtividade?.apresentacaoCapa}
          atvName={apresentacaoAtividade?.nome}
          atvTitle={apresentacaoAtividade?.titulo}
          codigoAtividade={codigoAtividade}
          isAtividadeCompleta={isAtividadeClicadaCompleta}
          atvExecuted={apresentacaoAtividade?.executed}
        />
      ) : null}{" "}
      {processando === true ? (
        <WeexSpinner
          aguarde={i18n.message(
            "geral.carregando.atividades",
            "Carregando atividades...",
          )}
        />
      ) : (
        <>
          {globalSchedule !== null ? (
            <MuralCampaigns
              globalSchedule={globalSchedule}
              emptyCampaignMural={emptyCampaignMural}
            />
          ) : (
            ""
          )}
          <div className="atividades__container">
            {campaignAtividades()}
            {rankingIndividual()}
            {rankingPorEquipe()}
          </div>
          {campaignFeedback()}
        </>
      )}
    </AtividadesStyle>
  );
}

export default Atividades;
